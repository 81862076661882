
/* https://ember-alt-wp.ecko.me/average-manager-vs-great-manager/ */

.post-contents * {
    font-family: "Lora", "Times New Roman", Serif;
  }
  
.post-contents {
    position: relative;
    width: 90%;
    max-width: 665px;
    margin: 80px auto;
    color: #4a4a4f
}

.post-contents>*:first-child {
    margin-top: 0
}

.post-contents>*:last-child {
    margin-top: 0
}

.post-contents>* {
    margin: 45px 0
}

@media (max-width: 710px) {
    .post-contents>* {
        margin:35px 0
    }
}

@media (max-width: 775px) {
    .post-contents {
        margin:60px auto
    }
}

@media (max-width: 620px) {
    .post-contents {
        margin:40px auto
    }
}

.post-contents .aligncenter {
    margin-right: auto;
    margin-left: auto;
    text-align: center
}

.post-contents .alignright {
    float: right;
    margin: 10px 0 30px 50px
}

.post-contents .alignleft {
    float: left;
    margin: 10px 50px 30px 0
}

@media (max-width: 700px) {
    .post-contents .aligncenter,.post-contents .alignleft,.post-contents .alignright {
        display:block;
        float: none
    }
}

.post-contents .alignwide {
    position: relative;
    width: 135%;
    max-width: 135%;
    margin-top: 65px;
    margin-right: -17.5%;
    margin-bottom: 65px;
    margin-left: -17.5%
}

@media (max-width: 980px) {
    .post-contents .alignwide {
        max-width:100%;
        margin-top: 45px;
        margin-right: 0;
        margin-bottom: 45px;
        margin-left: 0
    }
}

.post-contents .alignfull {
    position: relative;
    width: 100vw;
    max-width: 100vw;
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: calc((100vw - 100%) / -2);
    text-align: center
}

@media (max-width: 710px) {
    .post-contents .alignfull {
        margin-top:50px;
        margin-bottom: 50px
    }
}

.post-contents p,.post-contents ul,.post-contents ol {
    margin: 34px 0;
    letter-spacing: 0.1px;
    color: #4a4a4f
}

.post-contents p.has-medium-font-size,.post-contents ul.has-medium-font-size,.post-contents ol.has-medium-font-size {
    font-size: 20px;
    line-height: 36px
}

.post-contents p.has-large-font-size,.post-contents ul.has-large-font-size,.post-contents ol.has-large-font-size {
    font-size: 36px;
    line-height: 46px
}

.post-contents p.has-huge-font-size,.post-contents ul.has-huge-font-size,.post-contents ol.has-huge-font-size {
    font-size: 48px;
    line-height: 54px
}

@media (max-width: 1240px) {
    .post-contents p.has-medium-font-size,.post-contents ul.has-medium-font-size,.post-contents ol.has-medium-font-size {
        font-size:18px;
        line-height: 32px
    }

    .post-contents p.has-large-font-size,.post-contents ul.has-large-font-size,.post-contents ol.has-large-font-size {
        font-size: 28px;
        line-height: 42px
    }

    .post-contents p.has-huge-font-size,.post-contents ul.has-huge-font-size,.post-contents ol.has-huge-font-size {
        font-size: 38px;
        line-height: 48px
    }
}

@media (max-width: 775px) {
    .post-contents p.has-medium-font-size,.post-contents ul.has-medium-font-size,.post-contents ol.has-medium-font-size {
        font-size:18px;
        line-height: 32px
    }

    .post-contents p.has-large-font-size,.post-contents ul.has-large-font-size,.post-contents ol.has-large-font-size {
        font-size: 28px;
        line-height: 42px
    }

    .post-contents p.has-huge-font-size,.post-contents ul.has-huge-font-size,.post-contents ol.has-huge-font-size {
        font-size: 36px;
        line-height: 46px
    }
}

@media (max-width: 460px) {
    .post-contents p.has-large-font-size,.post-contents ul.has-large-font-size,.post-contents ol.has-large-font-size {
        font-size:24px;
        line-height: 38px
    }

    .post-contents p.has-huge-font-size,.post-contents ul.has-huge-font-size,.post-contents ol.has-huge-font-size {
        font-size: 32px;
        line-height: 42px
    }
}

@media (max-width: 710px) {
    .post-contents p,.post-contents ul,.post-contents ol {
        margin:30px 0
    }
}

.post-contents h1 {
    margin: 45px 0
}

@media (max-width: 710px) {
    .post-contents h1 {
        margin:40px 0
    }
}

.post-contents h2,.post-contents h3,.post-contents h4,.post-contents h5,.post-contents h6 {
    margin: 40px 0 35px
}

@media (max-width: 710px) {
    .post-contents h2,.post-contents h3,.post-contents h4,.post-contents h5,.post-contents h6 {
        margin:35px 0 30px
    }
}

.post-contents ul,.post-contents ol {
    padding: 0
}

.post-contents li {
    line-height: 34px;
    margin: 30px 0 30px 6%;
    padding: 0 0 0 2.5%
}

.post-contents li:first-of-type {
    margin-top: 0
}

.post-contents li:last-of-type {
    margin-bottom: 0
}

@media (max-width: 775px) {
    .post-contents li {
        font-size:16px;
        line-height: 30px
    }
}

@media (max-width: 710px) {
    .post-contents li {
        margin:25px 0 25px 6%
    }
}

@media (max-width: 460px) {
    .post-contents li {
        font-size:15px;
        line-height: 28px
    }
}

.post-contents ul li ul {
    margin: 24px 0
}

.post-contents ul li ul li {
    margin: 20px 0 20px 6%
}

.post-contents figure {
    margin: 50px 0
}

.post-contents figcaption {
    font-size: 16px;
    width: 100%;
    margin: 10px 0 10px;
    text-align: center;
    background: #fff
}

.post-contents blockquote {
    position: relative;
    display: block;
    width: 135%;
    max-width: 135%;
    height: auto;
    margin-top: 65px;
    margin-right: -17.5%;
    margin-bottom: 65px;
    margin-left: -17.5%;
    text-align: center
}

.post-contents blockquote p {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 20px;
    color: #212124
}

.post-contents blockquote cite {
    font-size: 18px;
    font-style: normal;
    color: #747474
}

.post-contents blockquote.is-large,.post-contents blockquote.is-style-large {
    margin-top: 65px;
    margin-right: -17.5%;
    margin-bottom: 65px;
    margin-left: -17.5%
}

.post-contents blockquote.is-large p,.post-contents blockquote.is-style-large p {
    font-style: normal;
    font-size: 32px;
    line-height: 54px
}

@media (max-width: 980px) {
    .post-contents blockquote {
        max-width:100%;
        margin-top: 45px;
        margin-right: 0;
        margin-bottom: 45px;
        margin-left: 0
    }

    .post-contents blockquote.is-large,.post-contents blockquote.is-style-large {
        margin-top: 45px;
        margin-right: 0;
        margin-bottom: 45px;
        margin-left: 0
    }

    .post-contents blockquote.is-large p,.post-contents blockquote.is-style-large p {
        font-size: 27px;
        line-height: 46px
    }
}

@media (max-width: 910px) {
    .post-contents blockquote p {
        line-height:36px
    }

    .post-contents blockquote.is-large,.post-contents blockquote.is-style-large {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .post-contents blockquote.is-large p,.post-contents blockquote.is-style-large p {
        font-size: 24px;
        line-height: 38px
    }
}

@media (max-width: 710px) {
    .post-contents blockquote {
        left:0;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        transform: none
    }

    .post-contents blockquote p {
        font-size: 20px;
        line-height: 34px
    }

    .post-contents blockquote cite {
        font-size: 16px
    }

    .post-contents blockquote.is-large,.post-contents blockquote.is-style-large {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .post-contents blockquote.is-large p,.post-contents blockquote.is-style-large p {
        font-size: 22px;
        line-height: 32px
    }
}

.post-contents q {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 32px;
    position: relative;
    display: inline;
    max-width: 250px;
    margin: 0 0 10px;
    margin: 0 30px 20px -50px;
    color: #212124
}

.post-contents q:before,.post-contents q:after {
    display: none
}

.post-contents q cite {
    font-size: 15px;
    font-style: normal;
    display: block;
    clear: both;
    color: #747474
}

@media (max-width: 910px) {
    .post-contents q {
        display:block;
        max-width: 100%;
        margin: 30px 0 40px;
        text-align: center
    }

    .post-contents q p {
        font-size: 20px;
        line-height: 32px
    }
}

.post-contents pre {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.375;
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 45px 0;
    padding: 55px;
    border-radius: 2px
}

@media (max-width: 910px) {
    .post-contents pre {
        width:100%;
        width: 100vw
    }
}

@media (max-width: 710px) {
    .post-contents pre {
        left:0;
        width: 100%;
        margin: 40px 0;
        transform: none
    }
}

.post-contents pre code {
    font-family: 'Source Code Pro', monospace, sans-serif;
    font-weight: 500;
    line-height: 29px
}

.post-contents table {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    width: 100%;
    height: auto;
    margin: 50px 0;
    border-collapse: collapse;
    border-radius: 2px
}

@media (max-width: 910px) {
    .post-contents table {
        width:100%;
        width: 100vw
    }
}

@media (max-width: 840px) {
    .post-contents table {
        left:0;
        width: 100%;
        margin: 40px 0;
        transform: none
    }
}

@media (max-width: 460px) {
    .post-contents table {
        margin:30px 0
    }
}

@media (max-width: 680px) {
    .post-contents table {
        border:0
    }

    .post-contents table caption {
        font-size: 13px
    }

    .post-contents table thead {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: none
    }

    .post-contents table tr {
        display: block;
        height: auto;
        margin-bottom: 30px;
        border-bottom: 3px solid #ddd
    }

    .post-contents table tr td:first-child {
        padding: 7px 0
    }

    .post-contents table tr:last-child {
        margin-bottom: 0
    }

    .post-contents table td {
        font-size: 13px;
        min-height: 44px;
        display: block;
        padding: 7px 0;
        text-align: right;
        border-bottom: 1px solid #ddd
    }

    .post-contents table td:before {
        font-weight: 600;
        float: left;
        content: attr(data-label)
    }

    .post-contents table td:last-child {
        border-bottom: 0
    }
}

.post-contents tr {
    height: 70px
}

.post-contents tr>td:first-child,.post-contents tr>th:first-child {
    padding-left: 30px
}

.post-contents th {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-weight: 600;
    color: #171718;
    border-bottom: 2px solid #e7e7e7;
    background: #f4f5f9
}

.post-contents td,.post-contents th {
    padding: 8px;
    text-align: left
}

.post-contents td {
    font-size: 14px;
    color: #555557;
    border-bottom: 1px solid #e7e7e7
}

.post-contents hr {
    width: 100%;
    height: 1px;
    margin: 50px 0;
    border: 0;
    background: #e7e7e7
}

@media (max-width: 710px) {
    .post-contents hr {
        margin:40px 0
    }
}

.post-contents dl {
    clear: both;
    overflow: hidden;
    margin: 45px 0
}

.post-contents dt {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 14px;
    font-weight: 700;
    display: block;
    float: left;
    clear: both;
    width: 25%;
    margin: 20px 0;
    padding: 0
}

.post-contents dd {
    font-family: "Lora","Times New Roman",Serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    display: block;
    float: left;
    width: 70%;
    margin: 20px 0 20px 2.5%;
    padding: 0 0 0 2.5%;
    border-left: 1px solid #e7e7e7
}

.post-contents iframe {
    max-width: 100%
}

.post-contents .fluid-width-video-wrapper,.post-contents twitterwidget {
    margin: 50px auto !important
}

@media (max-width: 710px) {
    .post-contents .fluid-width-video-wrapper,.post-contents twitterwidget {
        margin:40px auto !important
    }
}

.post-contents a {
    border-bottom: 1px solid #dedede;
    transition: color 0.2s ease-out, background 0.2s ease-out, opacity 0.2s ease-out, border 0.2s ease-out
}

.post-contents a:hover,.post-contents a:focus {
    border-color: #e7762c
}

.post-contents img {
    max-width: 100%;
    height: auto;
    border-radius: 2px
}

.post-contents strong,.post-contents b {
    color: #353539
}

.post-contents p>code,.post-contents p>tt {
    font: normal 14px 'Source Code Pro', monospace, sans-serif;
    padding: 1px 3px;
    white-space: pre-wrap;
    border: #dedede 1px solid;
    border-radius: 2px;
    background: #f9f9f9
}

.post-contents mark {
    background: rgba(231,118,44,0.3)
}

.post-contents fieldset {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 16px;
    font-weight: 600;
    margin: 50px 0;
    padding: 5%
}

.post-contents fieldset legend {
    padding: 0 10px
}

.post-contents label {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 15px;
    font-weight: 600
}

.post-contents input[type='text'],.post-contents input[type='number'],.post-contents input[type='url'],.post-contents input[type='email'],.post-contents input[type='password'],.post-contents input[type='tel'],.post-contents input[type='search'],.post-contents input[type='date'],.post-contents input[type='week'],.post-contents input[type='month'],.post-contents input[type='time'],.post-contents input[type='datetime'],.post-contents input[type='datetime-local'],.post-contents select {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    width: 260px;
    margin: 0 0 10px;
    padding: 12px 16px;
    border: #e0e0e0 1px solid;
    border-radius: 2px;
    outline: 0;
    background: #fff;
    box-shadow: none;
    -webkit-appearance: none;
    transition: color 0.2s ease-out, background 0.2s ease-out, opacity 0.2s ease-out, border 0.2s ease-out
}

.post-contents input[type='text']:focus,.post-contents input[type='number']:focus,.post-contents input[type='url']:focus,.post-contents input[type='email']:focus,.post-contents input[type='password']:focus,.post-contents input[type='tel']:focus,.post-contents input[type='search']:focus,.post-contents input[type='date']:focus,.post-contents input[type='week']:focus,.post-contents input[type='month']:focus,.post-contents input[type='time']:focus,.post-contents input[type='datetime']:focus,.post-contents input[type='datetime-local']:focus,.post-contents select:focus {
    border-color: #adadad;
    outline: none
}

.post-contents select {
    height: 40px;
    padding: 10px 14px;
    border-radius: 2px;
    -webkit-appearance: menulist
}

.post-contents textarea {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 15px;
    font-weight: 600;
    display: block;
    width: 100%;
    margin: 0 0 10px;
    padding: 16px 20px;
    border: #e0e0e0 1px solid;
    border-radius: 2px;
    transition: color 0.2s ease-out, background 0.2s ease-out, opacity 0.2s ease-out, border 0.2s ease-out
}

.post-contents textarea:focus {
    border-color: #adadad;
    outline: none
}

.post-contents input[type='reset'],.post-contents input[type='button'],.post-contents input[type='submit'],.post-contents input[type='image'],.post-contents button {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 10px;
    padding: 12px 36px;
    color: #fff;
    border: none;
    border-radius: 2px;
    outline: 0;
    background-color: #e7762c;
    transition: color 0.2s ease-out, background 0.2s ease-out, opacity 0.2s ease-out, border 0.2s ease-out
}

.post-contents input[type='reset']:hover,.post-contents input[type='reset']:focus,.post-contents input[type='button']:hover,.post-contents input[type='button']:focus,.post-contents input[type='submit']:hover,.post-contents input[type='submit']:focus,.post-contents input[type='image']:hover,.post-contents input[type='image']:focus,.post-contents button:hover,.post-contents button:focus {
    background-color: #c95d17
}

.post-contents svg.svg-icon-check-circle {
    margin-left: 5px;
    vertical-align: -3px;
    color: #7dc43b;
    stroke: #7dc43b
}

.post-contents .gallery-caption {
    line-height: 24px;
    margin: 0;
    padding: 10px 5%
}

.post-contents .post-tags a {
    font-family: "Montserrat","Arial",Sans-Serif;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    display: inline-block;
    margin: 25px 10px 0 0;
    padding: 10px 15px;
    color: #000;
    border: 0;
    border-radius: 2px;
    background: #f7f7f7
}

.post-contents .post-tags a:hover {
    color: #fff;
    background: #e7762c
}



/* https://ember-alt-wp.ecko.me/average-manager-vs-great-manager/ */

.post-contents p {
    margin-top: 10px;
    font-family: "Lora", "Times New Roman", Serif;
    font-size: 17.5px;
    font-weight: 400;
    line-height: 32px;
    margin: 34px 0;
    letter-spacing: 0.1px;
    color: #4a4a4f;
  }
  
  
  .post-contents ul li {
    line-height: 34px;
    margin: 30px 0 30px 6%;
    padding: 0 0 0 2.5%;
    display: list-item;
    text-align: -webkit-match-parent;
  }
  
  .post-contents ul {
    margin: 34px 0;
    letter-spacing: 0.1px;
    color: #4a4a4f;
  
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  
  
  .post-contents ol li {
    line-height: 34px;
    margin: 30px 0 30px 6%;
    padding: 0 0 0 2.5%;
    display: list-item;
    text-align: -webkit-match-parent;
  }
  
  .post-contents ol {
    margin: 34px 0;
    letter-spacing: 0.1px;
    color: #4a4a4f;
  
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  
  .post-contents h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  
    box-sizing: border-box;
    transition: width 0.2s ease-out, height 0.2s ease-out, padding 0.2s ease-out, border-color 0.2s ease-out, transform 0.2s ease-out, opacity 0.2s ease-out, background 0.2s ease-out, background-color 0.2s ease-out, -webkit-filter 0.2s ease-out;
    transition: width 0.2s ease-out, height 0.2s ease-out, padding 0.2s ease-out, border-color 0.2s ease-out, filter 0.2s ease-out, transform 0.2s ease-out, opacity 0.2s ease-out, background 0.2s ease-out, background-color 0.2s ease-out;
    transition: width 0.2s ease-out, height 0.2s ease-out, padding 0.2s ease-out, border-color 0.2s ease-out, filter 0.2s ease-out, transform 0.2s ease-out, opacity 0.2s ease-out, background 0.2s ease-out, background-color 0.2s ease-out, -webkit-filter 0.2s ease-out;
  
    font-family: "Hind", "Arial", Sans-Serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 51px;
    color: #141415;
    margin: 40px 0 35px;
  }

  .post-contents blockquote cite {
    font-size: 16px;
  }
  .post-contents blockquote p {
    margin-bottom: 2px;
  }

  blockquote:before {
    position: absolute;
    content: open-quote;
    font-size: 4em;
    top: -10px; 
    left: -20px;
  }
  blockquote:after {
    position: absolute;
    content: close-quote;
    font-size: 4em;
    bottom: 0;
    right: 0;
    bottom: -10px; 
    right: -20px;
  }